import axiosConfig from '../axiosConfig';

export type TSubscription = {
	id: string;
	name: string;
	status: string;
	description: string;
	version: number;
	owner: number;
};

export type TNewSubscription = Omit<TSubscription, 'id'>;

export class Subscription implements TSubscription {
	id: string;
	name: string;
	status: string;
	description: string;
	version: number;
	owner: number;

	constructor(sub: TSubscription) {
		this.id = sub.id;
		this.name = sub.name;
		this.status = sub.status;
		this.description = sub.description;
		this.version = sub.version;
		this.owner = sub.owner;
	}

	public static Default(): TNewSubscription {
		return {
			name: '',
			description: '',
			version: 0,
			status: '',
			owner: 1,
		};
	}

	public static GetAll(expand: (keyof TSubscription)[] = []): Promise<TSubscription[]> {
		return axiosConfig.instance
			.get(`/admin/subscriptions`, { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TSubscription[]).map((entity) => {
					return entity;
				});
			});
	}

	public static Get(id: number): Promise<TSubscription> {
		return axiosConfig.instance.get(`/admin/subscriptions/zone/${id}`, {}).then((res) => {
			return res.data as TSubscription;
		});
	}

	public static Delete(id: string): Promise<boolean> {
		return axiosConfig.instance.delete(`app/subscription/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TNewSubscription): Promise<TSubscription> {
		return axiosConfig.instance.post(`app/subscription`, entity).then((res) => {
			return res.data as TSubscription;
		});
	}

	public static Update(entity: TSubscription): Promise<TSubscription> {
		return axiosConfig.instance.patch('app/subscription', entity).then((res) => {
			return res.data as TSubscription;
		});
	}
}
