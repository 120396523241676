import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMount } from 'react-use';
import { Action, Column, Field, ISelectOption, UIType } from '@zeroedin-react/zi-common/lib/';
import SchnurTable from '@zeroedin-tech/zi-common-ui/lib/components/SchnurTable/SchnurTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalVariant } from '@patternfly/react-core';
import { DatabaseServer, TDatabaseServer } from '../api/databaseServer/DatabaseServer';
import { SubheaderContext } from '../layout/Layout';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import FilterTableLayout from '../layout/FilterTableLayout';
import { Subscriber, TZone, Zone } from '../api/zone/Zone';
import { DatabaseInstance } from '../api/databaseInstance/DatabaseInstance';
import { AxiosError } from 'axios';
import DeleteConfirmationModal from '../components/helpers/DeleteConfirmationModal';
import { Subscription, TSubscription } from '../api/subscriptions/Subscription';
import ZiForm, { IDualListOption } from '@zeroedin-react/zi-common/lib/components/ZiForm/ZiForm';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

export default function Zones(): ReactElement {
	const { addToast } = useToast();
	const [setSubheader]: SubheaderContext = useOutletContext();
	const [tableData, setTableData] = useState<TZone[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [activeZone, setActiveZone] = useState<TZone>(Zone.Default() as TZone);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [databaseServers, setDatabaseServers] = useState<TDatabaseServer[]>([]);
	const [currentZoneId, setCurrentZoneId] = useState<number>(0);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [subscriptions, setSubscriptions] = useState<TSubscription[]>([]);
	const [chosenSubscriptions, setChosenSubscriptions] = useState<string[]>([]);

	const selectedColumns: Column<TZone>[] = [
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Prefix',
			columnName: 'prefix',
		},
		{
			title: 'App Database',
			columnName: 'appDatabaseInstance',
			customAccessor: (item) => {
				if (typeof item.appDatabaseInstance === 'object') {
					return item.appDatabaseInstance.dbname;
				} else {
					return String(item);
				}
			},
		},
		{
			title: 'Data Database',
			columnName: 'appDatabaseInstance',
			customAccessor: (item) => {
				if (typeof item.appDatabaseInstance === 'object') {
					return item.appDatabaseInstance.dbname;
				} else {
					return String(item);
				}
			},
		},
		{
			title: 'Is Builder Zone',
			columnName: 'is_builder',
			customAccessor: (item) => {
				return String(item.is_builder ? 'Yes' : 'No');
			},
		},
	];

	const actions: Action<TZone>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				void Zone.Get(['subscribers', 'subscribers.subscription'], item.id.toString()).then(
					(response) => {
						item.subscribers = response.subscribers;
						setActiveZone(item);
						setIsModalOpen(true);
					}
				);
			},
		},
		{
			name: 'Delete',
			callback: (item) => {
				setCurrentZoneId(item.id);
				setIsDeleteModalOpen(true);
			},
		},
	];

	const formProperties: Field<TZone>[] = [
		{
			title: 'Nickname',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			required: true,
		},
		{
			title: 'Prefix',
			columnName: 'prefix',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'App Server',
			columnName: 'appDatabaseInstance',
			uiSchema: {
				type: UIType.SELECT,
				options: databaseServers.map((databaseServer) => {
					return {
						value: databaseServer.name,
						key: databaseServer.id,
						description: databaseServer.host,
					};
				}),
				onSelect: (option: ISelectOption) => {
					return option.key;
				},
				initialSelection:
					databaseServers.find((databaseServer) => {
						const appDatabaseInstance =
							activeZone.appDatabaseInstance as DatabaseInstance;

						const databaseServerObject =
							appDatabaseInstance.databaseServer as DatabaseServer;

						if (!appDatabaseInstance || !databaseServerObject) {
							return false;
						}

						return databaseServer?.id === databaseServerObject.id;
					})?.name ?? undefined,
			},
			required: true,
		},
		{
			title: 'Data Server',
			columnName: 'dataDatabaseInstance',
			uiSchema: {
				type: UIType.SELECT,
				options: databaseServers.map((databaseServer) => {
					return {
						value: databaseServer.name,
						key: databaseServer.id,
						description: databaseServer.host,
					};
				}),
				onSelect: (option: ISelectOption) => {
					return option.key;
				},
				initialSelection:
					databaseServers.find((databaseServer) => {
						const dataDatabaseInstance =
							activeZone.dataDatabaseInstance as DatabaseInstance;

						const databaseServerObject =
							dataDatabaseInstance.databaseServer as DatabaseServer;

						if (!dataDatabaseInstance || !databaseServerObject) {
							return false;
						}

						return databaseServer?.id === databaseServerObject.id;
					})?.name ?? undefined,
			},
			required: true,
		},
		{
			title: 'Is Builder Zone',
			columnName: 'is_builder',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
		{
			title: 'Subscriptions',
			columnName: 'subscribers',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: subscriptions.map(
					(sub) => ({ key: sub.id, value: sub.name } as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: chosenSubscriptions
					?.map((subscription: string) => {
						if (typeof subscription === 'string') {
							const foundSub = subscriptions.find((g) => g.id === subscription);
							return {
								key: foundSub?.id,
								value: foundSub?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
		},
	];

	useMount(() => {
		setSubheader(
			<PageTitleSubheader
				pageTitle="Zones"
				pageDescription="Manage your Zones."
			/>
		);

		loadPageData();
	});

	useEffect(() => {
		if (isModalOpen && activeZone.subscribers) {
			const hasSubscribers = activeZone.subscribers.some((x) => x.subscription);

			if (hasSubscribers) {
				const subcriptionList = activeZone.subscribers.map((x) => x.subscription.id);

				setChosenSubscriptions(subcriptionList);
			}
		}

		if (!isModalOpen) {
			setChosenSubscriptions([]);
		}
	}, [isModalOpen, activeZone]);

	const loadPageData = () => {
		setTableLoading(true);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Zone.GetAll([
			'appDatabaseInstance',
			'appDatabaseInstance.databaseServer',
			'dataDatabaseInstance',
			'dataDatabaseInstance.databaseServer',
			'subscribers',
			'subscribers.subscription',
		])
			.then((tableData) => {
				setTableData(tableData);
				setTableLoading(false);
			})
			.catch(() => {
				addToast('Failed to load Zones.', AlertVariant.danger);
			});

		DatabaseServer.GetAll()
			.then((databaseServers) => {
				setDatabaseServers(databaseServers);
				setIsFormLoading(false);
			})
			.catch(() => {
				addToast('Failed to load Database Servers.', AlertVariant.danger);
			});

		void Subscription.GetAll().then((response) => {
			setSubscriptions(response);
		});
	};

	const deleteZone = () => {
		const zoneId = currentZoneId;

		Zone.Delete(zoneId)
			.then(() => {
				setTableLoading(false);
				loadPageData();
				addToast('Zone deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				setTableLoading(false);
				addToast('Failed to delete Zone.', AlertVariant.danger);
			})
			.finally(() => {
				closeDeleteModal();
			});
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const addButton = (
		<Button
			data-testid={'add-button'}
			variant={'primary'}
			onClick={() => {
				setActiveZone(Zone.Default() as TZone);
				setIsModalOpen(true);
			}}
		>
			Provision new Zone
		</Button>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = () => {
		loadPageData();
		setIsModalOpen(false);

		addToast('Zone has successfully been sent to queue for processing', AlertVariant.success);
	};

	const entityTable = (
		<SchnurTable<TZone>
			ariaLabel={'Zones'}
			columns={selectedColumns}
			data={tableData}
			caption="Zones"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Zone Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<ZiForm<TZone>
					title={'Zone Management'}
					fields={formProperties}
					initialSubject={activeZone}
					isLoading={isFormLoading}
					onDualListSelectedOptionsChange={(options) => {
						if (options) {
							const entityIds = options?.map((x) => (x.key as string) ?? 0) ?? [];
							setChosenSubscriptions(entityIds);
						}
					}}
					onSubmit={(entity) => {
						setIsFormLoading(true);

						if (chosenSubscriptions.length > 0) {
							const subsribersToSave: Subscriber[] = [];

							chosenSubscriptions.map((sub) => {
								subsribersToSave.push({
									status: 'active',
									subscription: { id: sub.toString() },
									zone: { id: activeZone.id },
								});
							});

							entity.subscribers = subsribersToSave;
						}

						if ('id' in entity) {
							Zone.Update(entity, ['appDatabaseInstance', 'dataDatabaseInstance'])
								.then(() => {
									handleSuccess();
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to update the zone. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Zone.Provision(entity, ['appDatabaseInstance', 'dataDatabaseInstance'])
								.then(() => {
									handleSuccess();
								})
								.catch((_error: AxiosError) => {
									addToast(
										'An error occurred while trying to provision the zone. Please try again later.',
										AlertVariant.danger
									);
									setIsModalOpen(false);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={entityTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
			<DeleteConfirmationModal
				isOpen={isDeleteModalOpen}
				onClose={closeDeleteModal}
				onSubmit={deleteZone}
				titleText="Delete Zone Confirmation"
			/>
		</React.Fragment>
	);
}
